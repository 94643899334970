
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media (max-width: 599px) {
  .hidden-xs {
      display: none !important;
  }
}

@media (min-width: 600px) and (max-width: 959px) {
  .hidden-sm {
      display: none !important;
  }
}

@media (min-width: 960px) and (max-width: 1279px) {
  .hidden-md {
      display: none !important;
  }
}

@media (min-width: 1280px) and (max-width: 1919px) {
  .hidden-lg {
      display: none !important;
  }
}

@media (min-width: 1920px) and (max-width: 2559px) {
  .hidden-xl {
      display: none !important;
  }
}

@media (min-width: 2560px) {
  .hidden-xxl {
      display: none !important;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.icon {
  grid-area: icon;
}

.app-container {
  height: 100%;
  overflow:hidden;
  display: grid;
  grid-template-columns: 60px auto auto;
  grid-template-rows: 40px auto;
  grid-template-areas: 
    "icon header header"
    "sidebar main main";

    .header {
      grid-area: header;
    }
    
    .sidebar {
      grid-area: sidebar;
      padding-top: "20px";
      height: calc(100vh - 42px);
      overflow: hidden;
    }
    
    .main {
      grid-area: main;
      height: calc(100vh - 40px);
      overflow:auto;
      border-left: 1px gray solid;
      border-top: 1px gray solid;
    }
}

.twoColumnContainer {
  height: calc(100vh - 40px);
  overflow: hidden;
  display: grid;
  grid-template-columns: 260px auto;
  grid-template-rows: auto;
  grid-template-areas: "nav main";

  .nav {
    grid-area: nav;
    height: 100vh;
  }

  .body {
    grid-area: main;
    overflow: auto;
  }
}

.homePageContainer {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 160px auto;
  grid-template-areas: 
    "top"
    "bottom";
  height: 100%;
  
  .topContainer {
    grid-area: top;

    .cardContainer {
      margin: 20px;
      padding: 20px;
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
    }

    .card {
      width: 300px;
    }
  }

  .bottomContainer {
    grid-area: bottom;
    .cardContainer {
      margin: 20px;
    }
  }
}

.areaPageContainer {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 150px auto;
  grid-template-areas: 
    "top"
    "bottom";
  
  .topContainer {
    grid-area: top;
  }

  .bottomContainer {
    grid-area: bottom;
  }
}

.fadedSection {
  background-color: rgba(200, 200, 200, 0.2);
  border-radius: 5px;
  padding: 5px;
}

@media (max-width: 959px) {
  .actionHeaderContainer {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-template-areas: "actions";
    padding-top: 20px;
    padding-left: 40px;
  
    .title {
      display: none;
    }
  
    .actions {
      align-items: center;
      text-align: center;
      margin: auto;
      grid-area: actions;
    }
  }
}

@media (min-width: 960px) {
  .actionHeaderContainer {
    display: grid;
    grid-template-columns: auto 20%;
    grid-template-rows: auto;
    grid-template-areas: "title actions";
    padding-top: 20px;
    padding-left: 40px;
  
    .title {
      grid-area: title;
      margin-right: 10px;
      max-height: 100px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  
    .actions {
      align-items: center;
      text-align: center;
      margin: auto;
      grid-area: actions;
    }
  }
}

.fui-TableHeader {
  width: 100% !important;
}


.rangeSlider {
    position: relative;
    display: flex;
    align-items: center;
    margin: 40px calc(16px / 2);
    padding-top: 1.6rem;
    height: calc(16px + 1.6rem);
  
  .input-wrapper {
    width: calc(100% + 16px);
    margin: 0 calc(16px / -2);
    position: absolute;
    height: 16px;

    .input {
      position: absolute;
      width: 100%;
      pointer-events: none;
      -webkit-appearance: none;
         -moz-appearance: none;
              appearance: none;
      height: 100%;
      opacity: 0;
      z-index: 3;
      padding: 0;
    }
    .input::-ms-track {
      appearance: none;
      background: transparent;
      border: transparent;
    }
    .input::-moz-range-track {
      -moz-appearance: none;
           appearance: none;
      background: transparent;
      border: transparent;
    }
    .input:focus::-webkit-slider-runnable-track {
      -webkit-appearance: none;
              appearance: none;
      background: transparent;
      border: transparent;
    }
    .input::-ms-thumb {
      appearance: none;
      pointer-events: all;
      width: 16px;
      height: 16px;
      border-radius: 0px;
      border: 0 none;
      cursor: grab;
      background-color: red;
    }
    .input::-ms-thumb:active {
      cursor: grabbing;
    }
    .input::-moz-range-thumb {
      -moz-appearance: none;
           appearance: none;
      pointer-events: all;
      width: 16px;
      height: 16px;
      border-radius: 0px;
      border: 0 none;
      cursor: grab;
      background-color: red;
    }
    .input::-moz-range-thumb:active {
      cursor: grabbing;
    }
    .input::-webkit-slider-thumb {
      -webkit-appearance: none;
              appearance: none;
      pointer-events: all;
      width: 16px;
      height: 16px;
      border-radius: 0px;
      border: 0 none;
      cursor: -webkit-grab;
      cursor: grab;
      background-color: red;
    }
    .input::-webkit-slider-thumb:active {
      cursor: -webkit-grabbing;
      cursor: grabbing;
    }
  }
  
  .control-wrapper {
    width: 100%;
    position: absolute;
    height: 16px;

    .control {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      position: absolute;
      background: hotpink;
      top: 50%;
      margin-left: calc(16px / -2);
      transform: translate3d(0, -50%, 0);
      z-index: 2;
    }

    .rail {
      position: absolute;
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
      height: 6px;
      border-radius: 3px;
      background: lightgrey;
  
      .inner-rail {
        position: absolute;
        height: 100%;
        background: hotpink;
        opacity: 0.5;
      }
    }
  }

  .PDF-container-document {
    width: 100%;
    max-width: calc(100% - 2em);
    margin: 1em 0;
  }
  
  .PDF-container-document .react-pdf__Document {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .PDF-container-document .react-pdf__Page {
    margin: 1em 0;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  }
  
  .PDF-container-document .react-pdf__message {
    padding: 20px;
    color: white;
  }

  .PDF-container-document .react-pdf__Document .react-pdf__Page .react-pdf__Page__canvas {
    border: 1px solid black !important;
  }
}